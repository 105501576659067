import React, { Fragment, memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Cell, Pie, PieChart } from 'recharts';
import { chartData, colors } from './config';
import Tooltip from '../Tooltip';

import ps from '../style.module.scss';
import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

const TooltipContent = (props) => {
	const { active } = props;

	if (active) {
		const { payload, total, totalConnected } = props;
		const units = (payload[0].value / totalConnected) * 100;

		return (
			<div className={s.tooltip}>
				<h3 className={s.tipTitle}>
					<i style={{ backgroundColor: payload[0].payload.fill }} />
					<div style={{ fontSize: '10px' }}>{i18next.t(payload[0].name)}</div>
				</h3>
				<ul className={s.tipList}>
					<li>
						{i18next.t('Occurrences')} <strong>{payload[0].value}</strong>
					</li>
					<li>
						{i18next.t('total_errors')}{' '}
						<strong>
							{((payload[0].value / total) * 100)
								.toFixed(1)
								.replace(i18next.language === 'fr' ? '.' : 'x', ',')}{' '}
							%
						</strong>
					</li>
					<li>
						{i18next.t('total_units')}{' '}
						<strong>
							{units < 1
								? '<1'
								: units !== 0
								  ? units
											.toFixed(1)
											.replace(i18next.language === 'fr' ? '.' : 'x', ',')
								  : 0}{' '}
							% <span className={s.asterix}>*</span>
						</strong>
					</li>
					<li className={s.notes}>
						<span className={s.asterix}> * </span>
						{i18next.t('total_number_of_apps')}
					</li>
				</ul>
			</div>
		);
	}

	return null;
};

const RecentError = (props) => {
	const [tip, setTip] = useState();
	const pieChartRef = useRef(null);

	const [isTooltipActive, setTooltipState] = useState(false);
	const [tooltipPayload, setTooltipPayload] = useState({});
	const [coordinate, setTooltipCoordinate] = useState({});

	const { data, period, total, params } = props;
	const totalAmount = data.reduce((acc, val) => (acc += val.amount), 0);

	const mouseEnterHandler = (e, data) => {
		e.stopPropagation();
		setTip({ x: e.currentTarget.parentNode.offsetLeft, data });
	};

	const setViewBox = () => {
		return {
			width: pieChartRef.current.container.clientWidth,
			height: pieChartRef.current.container.clientHeight,
		};
	};

	const showTooltip = (e) => {
		setTooltipPayload(e.tooltipPayload);
		setTooltipState(true);
		setTooltipCoordinate(e.tooltipPosition);
	};

	const errorListClickHandler = (index) => {
		return props.errorClick(data[index], params);
	};

	const getDescription = (el) => {
		if (!el.concentrator_error) return el.code;

		const err =
			props.env === 'engineering'
				? el?.concentrator_error?.description_engineer
				: el?.concentrator_error?.description;
		return i18next.t(err);
	};

	const content = (
		<Fragment>
			<div className={s.chart} onMouseLeave={() => setTooltipState(false)}>
				<PieChart width={210} height={210} ref={pieChartRef}>
					<Pie
						data={chartData(data)}
						dataKey='value'
						cx={100}
						cy={100}
						innerRadius={50}
						outerRadius={100}
						onMouseEnter={showTooltip}
					>
						{data.map((entry, index) => (
							<Cell key={index} fill={colors[entry.error_id]} stroke='none' />
						))}
					</Pie>
				</PieChart>
				{isTooltipActive ? (
					<Tooltip
						auto={true}
						viewBox={setViewBox()}
						active={isTooltipActive}
						coordinates={coordinate}
						offset={10}
						onMouseLeave={() => setTooltipState(false)}
					>
						<TooltipContent
							total={totalAmount}
							totalConnected={total}
							active={isTooltipActive}
							payload={tooltipPayload}
						/>
					</Tooltip>
				) : null}
			</div>

			<ul
				className={`${s.errorList} ${props.width === 'full' && s.twoColumns}`}
				onMouseLeave={() => setTip()}
			>
				{data.map((el, index) => (
					<li
						key={index}
						className={`${s.item} ${props.width === 'full' && s.width}`}
						onClick={() => errorListClickHandler(index)}
					>
						<span
							className={s.marker}
							style={{ backgroundColor: colors[el.error_id] }}
						/>
						{getDescription(el)}
						<span className={s.dots} />
						<strong>{el.amount}</strong>
						<span className={s.scale}>
							<span
								onMouseEnter={(e) => mouseEnterHandler(e, el)}
								style={{
									width: `${(el.amount / data[0]?.amount) * 100}%`,
									backgroundColor: colors[el.error_id],
								}}
							/>
						</span>

						{tip && tip.data.error_id === el.error_id && (
							<div className={s.tooltip} style={{ left: `${tip.x}px` }}>
								<h3 className={s.tipTitle}>
									<i style={{ backgroundColor: colors[el.error_id] }} />
									{el.concentrator_error
										? i18next.t(el.concentrator_error.description)
										: i18next.t(el.code)}
								</h3>
								<ul className={s.tipList}>
									<li>
										{i18next.t('Occurrences')}{' '}
										<strong>{tip.data.amount}</strong>
									</li>
									<li>
										{i18next.t('total_errors')}{' '}
										<strong>
											{((tip.data.amount / totalAmount) * 100)
												.toFixed(1)
												.replace(
													i18next.language === 'fr' ? '.' : 'x',
													',',
												)}{' '}
											%
										</strong>
									</li>
									<li>
										{' '}
										{i18next.t('of_total_units')}
										<strong>
											{(tip.data.amount / total) * 100 < 1
												? '<1'
												: (tip.data.amount / total) * 100 !== 0
												  ? ((tip.data.amount / total) * 100)
															.toFixed(1)
															.replace(
																i18next.language === 'fr' ? '.' : 'x',
																',',
															)
												  : 0}
											% <span className={s.asterix}>*</span>
										</strong>
									</li>
									<li className={s.notes}>
										<span className={s.asterix}> * </span>
										{i18next.t('total_number_of_apps')}
									</li>
								</ul>
							</div>
						)}
					</li>
				))}
			</ul>
		</Fragment>
	);

	return (
		<div className={ps[props.width]}>
			<div className={ps.panel}>
				<h2 className={ps.title}>
					{i18next.t('most_recent_error')}
					<span>{i18next.t(period)}</span>
				</h2>

				<div className={s.inner}>
					{data.length ? (
						content
					) : (
						<div className={s.noShow}>{i18next.t('nothing_to_show')}</div>
					)}
				</div>
			</div>
		</div>
	);
};

RecentError.defaultProps = {
	data: [],
	period: '',
	total: 1,
	width: 'twoThird',
	errorClick: () => {},
	env: 'dashboard',
};

RecentError.propTypes = {
	data: PropTypes.array,
	period: PropTypes.string,
	total: PropTypes.number,
	width: PropTypes.string,
	errorClick: PropTypes.func,
};

export default memo(withTranslation()(RecentError));
