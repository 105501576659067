import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import {
	Modal,
	Button,
	Input,
	ValidateError,
	UploadAvatar,
	Avatar,
} from 'components';
import { checkValidity } from 'helpers';
import { Off, View, Edit } from 'components/Icons';
import Controls from './Config/controls';
import { companyName } from 'config';
import cs from 'classnames';
import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import passwordValidator from 'password-validator';

const schemaMin8 = new passwordValidator();
const schemaUppercase = new passwordValidator();
const schemaLowercase = new passwordValidator();
const schemaDigits = new passwordValidator();
schemaMin8.is().min(8);
schemaUppercase.has().uppercase();
schemaLowercase.has().lowercase();
schemaDigits.has().digits(1);

class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			controls: new Controls({ showHidePassword: this.showHidePassword }),
			isModalOpen: false,
			saveImageFlag: true,
			userName: this.props.userData.name || '',
			passwordGuidelines: {
				min: false,
				uppercase: false,
				lowercase: false,
				digits: false,
			},
		};
	}

	inputChangedHandler = (event, controlName) => {
		const target = event.target;
		const name = event.target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const controls = state[controlName];
			let formIsValid = true;
			let passwordGuidelines = { ...prevState.passwordGuidelines };

			controls.touched = false;
			controls.value = value;
			controls.valid = checkValidity(value, controls.validation);

			if (name === 'password') {
				passwordGuidelines.min = schemaMin8.validate(value);
				passwordGuidelines.uppercase = schemaUppercase.validate(value);
				passwordGuidelines.lowercase = schemaLowercase.validate(value);
				passwordGuidelines.digits = schemaDigits.validate(value);
			}

			for (let control in state) {
				formIsValid = state[control].valid && formIsValid;
			}

			return {
				passwordGuidelines,
				controls: state,
				formIsValid: formIsValid,
				errors: null,
			};
		});
	};

	// inputFocusOutHandler = (event, controlName) => {
	//     this.setState((prevState) => {
	//         const state = { ...prevState.controls };
	//         const controls = state[controlName];

	//         controls.touched = true;

	//         return { controls: state };
	//     });
	// }

	showHidePassword = (input) => {
		const pass = this.state.controls[input];
		const passConfig = pass.elementConfig;
		const type = passConfig.type === 'password' ? 'text' : 'password';

		this.setState((prevState) => {
			const controls = { ...prevState.controls };
			const iconProps = { type: 'show' };

			controls[input].icon.props.type === 'show' && (iconProps.type = 'hide');
			controls[input].elementConfig.type = type;
			controls[input].icon = (
				<View {...iconProps} onClick={() => this.showHidePassword(input)} />
			);

			return { controls };
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		const { controls, formIsValid } = this.state;
		const { currentPassword, password, repeatPassword } = controls;
		const { changePassword } = this.props;

		if (formIsValid) {
			if (password.value === repeatPassword.value) {
				const args = {
					oldPassword: currentPassword.value,
					newPassword: password.value,
					success: () => {
						this.modalCloseHandler();
						this.setState({ isAlertOpen: true });
					},
					error: (data) => this.setState({ errors: data }),
				};

				changePassword(args);
			} else {
				const errors = {
					message: 'Password do not match',
				};

				this.setState({ errors });
			}
		} else {
			this.setState((prevState) => {
				const controls = { ...prevState.controls };

				for (const control in controls) {
					controls[control].touched = true;
				}

				return { controls };
			});
		}
	};

	logOut = () => {
		this.props.onLogout();
	};

	modalCloseHandler = () =>
		this.setState(() => {
			return {
				controls: new Controls({ showHidePassword: this.showHidePassword }),
				isModalOpen: false,
				errors: null,
				passwordGuidelines: {
					min: false,
					uppercase: false,
					lowercase: false,
					digits: false,
				},
			};
		});

	alertCloseHandler = () =>
		this.setState({ isAlertOpen: false }, () => {
			localStorage.removeItem('token');
			sessionStorage.removeItem('token');

			window.location.reload();
		});

	openEditUserDialog = () =>
		this.setState({
			isEditUserOpen: true,
			selectedImagePath: this.props.userData.icon,
		});

	handleSelectPhoto = (event) => {
		const errors = {
			message: '',
		};
		this.setState({ errors, saveImageFlag: true });
		this.setState({
			selectedImagePath: event.target.files[0]
				? URL.createObjectURL(event.target.files[0])
				: '',
			selectedImage: event.target.files[0],
		});
		const imagefile = event.target.files[0];
		if (imagefile && imagefile.name) {
			var Type = imagefile.name.split('.').at(-1);
		}
		const allowedFileTypes = ['jpg', 'png', 'jfif', 'jpeg'];
		if (!allowedFileTypes.includes(Type)) {
			const errors = {
				message: i18next.t('images_can_be_png_or_jpeg_less_than_10mb'),
			};
			this.setState({ errors, saveImageFlag: false });
		}
		if (imagefile && imagefile.size) {
			if (imagefile.size > 10e6) {
				//10e6 === 10mb
				const errors = {
					message: i18next.t('images_can_be_png_or_jpeg_less_than_10mb'),
				};
				this.setState({ errors, saveImageFlag: false });
			}
		}
	};
	userProfileSubmitDialog = (event) => {
		event.preventDefault();

		const { saveUserProfile } = this.props;

		const args = {
			icon: this.state.selectedImage,
			name: this.state.userName,
			success: () => {
				this.userDialogCloseHandler();
			},
			error: (data) => this.setState({ errors: data }),
		};

		saveUserProfile(args);
	};

	userDialogCloseHandler = () =>
		this.setState(() => {
			return {
				isEditUserOpen: false,
				errors: null,
			};
		});

	handleUserNameInput = (event) => {
		const errors = {
			message: '',
		};
		this.setState({ errors, saveImageFlag: true });
		if (event.target.value === '') {
			const errors = {
				message: 'please enter username',
			};
			this.setState({ errors, saveImageFlag: false });
		}
		this.setState({
			userName: event.target.value,
		});
	};

	handleDeleteImg = (event) => {
		event.preventDefault();

		this.setState({
			selectedImagePath: '',
			selectedImage: null,
		});
	};

	render() {
		const { controls, isModalOpen, isAlertOpen, errors, isEditUserOpen } =
			this.state;
		const { email, name, roles, icon, social_users } = this.props.userData;
		const { t } = this.props;
		const modalInputProps = (type) => ({
			name: controls[type].name,
			label: controls[type].label,
			theme: controls[type].theme,
			className: controls[type].className,
			elementType: controls[type].elementType,
			elementConfig: controls[type].elementConfig,
			checked: controls[type].value,
			value: controls[type].value,
			invalid: !controls[type].valid,
			invalidMsg: t(controls[type].errorMsg),
			shouldValidate: controls[type].validation,
			touched: controls[type].touched,
			changed: (event) => this.inputChangedHandler(event, type),
			// onBlur: (event) => this.inputFocusOutHandler(event, type),
			icon: controls[type].icon,
		});
		function handleLabels(data) {
			return Array.isArray(data)
				? data.map((item) => {
						return {
							...item,
							label: item.label ? i18next.t(item.label) : '',
						};
				  })
				: [];
		}

		return (
			<div className={s.page}>
				<div className={s.pageHead}>
					<h1 className={s.title}>{name || t('my_profile')}</h1>
					<button className={s.updateBtn} onClick={this.openEditUserDialog}>
						<Edit /> {t('edit_profile')}
					</button>
					<button className={s.logoutBtn} onClick={this.logOut}>
						<Off /> {t('auth_logout')}
					</button>
				</div>
				<div className={s.userInfo}>
					<Avatar img={icon} className={s.userPhoto} />
					<ul className={s.infoList}>
						<li>{email}</li>
						{handleLabels(roles) &&
							handleLabels(roles).length > 0 &&
							handleLabels(roles).map((el) => {
								return <li key={el.id}>{el.label}</li>;
							})}
					</ul>
				</div>
				{social_users && !social_users.length ? (
					<div className={s.userButtons}>
						<Button
							btnType='type-14'
							clicked={() => this.setState({ isModalOpen: true })}
						>
							{t('change_password')}
						</Button>
					</div>
				) : null}

				<Modal
					className={s.modal}
					show={isModalOpen}
					modalClosed={this.modalCloseHandler}
				>
					<div className={s.changePass}>
						<h2 className={s.modalTitle}>{t('change_password')}</h2>

						<form onSubmit={this.submitHandler}>
							<div className={s.field}>
								<label className={s.label}>{t('current_password')}</label>
								<Input {...modalInputProps('currentPassword')} />
							</div>
							<div className={s.newPasswordsBox}>
								<div
									className={[s.field, s.alignInput].join(' ')}
									style={{ display: 'flex', flexDirection: 'column' }}
								>
									<label className={s.label}>{t('new_password')}</label>
									<Input {...modalInputProps('password')} />
									<Input {...modalInputProps('repeatPassword')} />
								</div>
								<div className={s.guidelinesPwd}>
									<h4
										dangerouslySetInnerHTML={{
											__html: t('passwordGuidelines_title'),
										}}
									/>
									<ul>
										<li
											className={cs({
												[s.correct]: this.state.passwordGuidelines.lowercase,
											})}
											dangerouslySetInnerHTML={{
												__html: t('passwordGuidelines_lowercase'),
											}}
										/>
										<li
											className={cs({
												[s.correct]: this.state.passwordGuidelines.uppercase,
											})}
											dangerouslySetInnerHTML={{
												__html: t('passwordGuidelines_uppercase'),
											}}
										/>
										<li
											className={cs({
												[s.correct]: this.state.passwordGuidelines.digits,
											})}
											dangerouslySetInnerHTML={{
												__html: t('passwordGuidelines_digits'),
											}}
										/>
										<li
											className={cs({
												[s.correct]: this.state.passwordGuidelines.min,
											})}
											dangerouslySetInnerHTML={{
												__html: t('passwordGuidelines_min'),
											}}
										/>
									</ul>
								</div>
							</div>
							<ValidateError data={errors} className={s.error} />
							<Button
								btnType='type-1'
								className={s.saveBtn}
								disabled={
									!(
										this.state.passwordGuidelines.min === true &&
										this.state.passwordGuidelines.lowercase === true &&
										this.state.passwordGuidelines.uppercase === true &&
										this.state.passwordGuidelines.digits === true
									)
								}
							>
								{t('save')}
							</Button>
						</form>
					</div>
				</Modal>

				<Modal
					className={s.alert}
					show={isAlertOpen}
					modalClosed={this.alertCloseHandler}
				>
					<h2 className={s.alertTitle}>
						{t('your_password_was_successfully_changed', {
							companyName: companyName,
						})}
					</h2>
					<Button btnType='type-6' clicked={this.alertCloseHandler}>
						OK
					</Button>
				</Modal>

				<Modal
					className={`${s.modal} ${s.userProfileDialog}`}
					show={isEditUserOpen}
					modalClosed={this.userDialogCloseHandler}
				>
					<div className={s.contentWrapper}>
						<h2 className={s.modalTitle}>{i18next.t('edit_profile')}</h2>
						<form
							className={s.userProfileForm}
							onSubmit={this.userProfileSubmitDialog}
						>
							<div className={s.field}>
								<label className={s.label}>{t('user_name')}</label>
								<Input
									changed={this.handleUserNameInput}
									value={this.state.userName}
								/>
							</div>
							<div className={`${s.field} ${s.uploadAvatar}`}>
								<UploadAvatar
									description={t('images_can_be_png_or_jpeg_less_than_10mb')}
									onChange={this.handleSelectPhoto}
									imgPreview={this.state.selectedImagePath}
									uploadBtnText={t('upload_image')}
									removeBtnText={t('remove_image')}
									onDeleteImg={this.handleDeleteImg}
								/>
							</div>
							<ValidateError data={errors} className={s.error} />
							<Button
								btnType='type-12'
								className={s.saveUserBtn}
								disabled={!this.state.saveImageFlag}
							>
								{t('save')}
							</Button>
						</form>
					</div>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		userData: state.user.data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLogout: () => dispatch(actions.logout()),
		changePassword: (args) => dispatch(actions.changePassword(args)),
		saveUserProfile: (args) => dispatch(actions.saveUserProfile(args)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(Profile));
