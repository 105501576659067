import React from 'react';

import s from '../../style.module.scss';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const FiltersList = (props) => {
	const {
		controlsGroups,
		allChecked,
		checkboxChangedHandler,
		checkAllHandler,
		t,
	} = props;
	const { errors, models, dataValues, FirmwareData_LV, FirmwareData_OV } =
		controlsGroups;

	if (allChecked) {
		return (
			<ul className={s.checkedList}>
				<li>
					{t('all_devices')}
					<i className={s.remove} onClick={checkAllHandler} />
				</li>
			</ul>
		);
	}

	return (
		<ul className={s.checkedList}>
			{Object.values(errors).map((el, index) => (
				<li key={index}>
					{el.description}
					<i
						className={s.remove}
						onClick={(e) => checkboxChangedHandler(e, el, 'errors')}
					/>
				</li>
			))}
			{Object.values(dataValues).map((el, index) => (
				<li key={index}>
					{el.name}
					<i
						className={s.remove}
						onClick={(e) => checkboxChangedHandler(e, el, 'dataValues')}
					/>
				</li>
			))}
			{Object.values(FirmwareData_LV).map((el, index) => (
				<li key={index}>
					<>{el.name} latest version</>
					<i
						className={s.remove}
						onClick={(e) => checkboxChangedHandler(e, el, 'FirmwareData_LV')}
					/>
				</li>
			))}
			{Object.values(FirmwareData_OV).map((el, index) => (
				<li key={index}>
					<>{el.name} old version</>
					<i
						className={s.remove}
						onClick={(e) => checkboxChangedHandler(e, el, 'FirmwareData_OV')}
					/>
				</li>
			))}
			{Object.values(models).map((el, index) => (
				<li key={index}>
					{el.description}
					<i
						className={s.remove}
						onClick={(e) => checkboxChangedHandler(e, el, 'models')}
					/>
				</li>
			))}
		</ul>
	);
};

FiltersList.propTypes = {
	errors: PropTypes.object.isRequired,
	models: PropTypes.array.isRequired,
	dataValues: PropTypes.object.isRequired,
	FirmwareData_LV: PropTypes.object.isRequired,
	FirmwareData_OV: PropTypes.object.isRequired,
};

export default withTranslation()(FiltersList);
