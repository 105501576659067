const applyFilter = (item, filters) => {
	return (
		filters &&
		filters.reduce((sum, filter) => {
			if (filter.isActive && item[filter.value]) {
				return sum + item[filter.value];
			}

			return sum;
		}, 0)
	);
};

export default (state, minValue, maxValue, step, filters) => {
	const data = [];
	const isFilterEnabled = filters.some((filter) => filter.isActive);

	/**
	 * 0 – 100 Watts as a valid value for system power, Everything else can be discarded as an inaccurate transmission
	 */
	const maxStep =
		maxValue > minValue ? (maxValue >= 100 ? 100 : maxValue) : minValue;

	for (var i = 0; i <= maxStep; i = i + step) {
		const item = {
			name: i,
			value: state[i]
				? isFilterEnabled
					? applyFilter(state[i], filters)
					: state[i].all
				: 0,
		};

		data.push(item);
	}

	return data;
};
