import React from 'react';
import s from './style.module.scss';

const ErrorStatusPopup = (props) => {
	const { ErrorStatusdata } = props;
	let recentErrorsStatusBody = (
		<div className={s.noFound}>No errors were found</div>
	);
	if (ErrorStatusdata.data) {
		recentErrorsStatusBody = (
			<table className={s.recentError}>
				<thead>
					<tr>
						<th>
							<b>Serial Number</b>
						</th>
						<th>
							<b>Error Count</b>
						</th>
					</tr>
				</thead>
				<tbody>
					{ErrorStatusdata?.data.length > 0 &&
						ErrorStatusdata?.data.map((row, index) => {
							const snNum = row?.SerailNum || {};
							const count = row?.count || '';
							return (
								<tr key={index}>
									<td>{snNum}</td>
									{count > 5 ? (
										<td className={s.countColor}>{count}</td>
									) : (
										<td>{count}</td>
									)}
								</tr>
							);
						})}
				</tbody>
			</table>
		);
	}

	return (
		<>
			<h2 className={s.title}>
				{ErrorStatusdata?.Error_Name}
				<span className={s.msg}>Last 30 days</span>
			</h2>
			<div className={s.pane}>
				<div></div>
				<div className={s.paneBody}>{recentErrorsStatusBody}</div>
			</div>
		</>
	);
};

export default ErrorStatusPopup;
