import { createSelector } from 'reselect';
import { unknownProvider } from '../../../containers/UsersProviders/Configs/constants';

const getCurrentUser = (state) => state.user.data;
const getPendingUsers = (state) => state.manageUsers.usersPending.data;
const getPendingUsersLoading = (state) =>
	state.manageUsers.usersPending.loading;
const getProviders = (state) =>
	state.providers.data.filter(
		(el) =>
			el.id !== unknownProvider.ID &&
			el.name.toLowerCase() !== unknownProvider.NAME.toLowerCase(),
	);
const getRoles = (state) => state.roles.data;
const getSearchUsers = (state) => state.manageUsers.searchUsers.data;
const getSearchUsersLoading = (state) => state.manageUsers.searchUsers.loading;
const getSearchProviders = (state) => state.manageUsers.searchProviders.data;
const getSearchProvidersLoading = (state) =>
	state.manageUsers.searchProviders.loading;
const getManageProvider = (state) => state.manageUsers.provider.data;
const getManageProviderLoading = (state) => state.manageUsers.provider.loading;

export const makeGetAllManageUserState = () =>
	createSelector(
		getCurrentUser,
		getPendingUsers,
		getPendingUsersLoading,
		getProviders,
		getRoles,
		getSearchUsers,
		getSearchUsersLoading,
		getSearchProviders,
		getSearchProvidersLoading,
		getManageProvider,
		getManageProviderLoading,
		(
			currentUserData,
			pendingUsersData,
			pendingUsersLoading,
			providersData,
			rolesData,
			searchUsersData,
			searchUsersLoading,
			searchProvidersData,
			searchProvidersLoading,
			manageProviderData,
			manageProviderLoading,
		) => ({
			currentUserData,
			pendingUsersData,
			pendingUsersLoading,
			providersData,
			rolesData,
			searchUsersData,
			searchUsersLoading,
			searchProvidersData,
			searchProvidersLoading,
			manageProviderData,
			manageProviderLoading,
		}),
	);
