import React, { memo, Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { PieChart, Pie, Cell } from 'recharts';
import { colors, chartData, TOP_CATEGORIES_AMOUNT } from './config';
import Tooltip from '../Tooltip';

import ps from '../style.module.scss';
import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

const normalisePieceValue = (val) => {
	if (val >= 0.01) {
		return val.toFixed(2).replace(i18next.language === 'fr' ? '.' : 'x', ',');
	}

	return '< 0.01';
};

const OtherTooltip = (props) => {
	const { active, payload, data, models, t } = props;
	const mainData = data.filter((el) => el.piece >= 1);
	const tooltipOther = data.filter((el) => el.piece < 1);
	let result = 0;
	let totalOtherPiece = 0;
	tooltipOther.forEach(({ piece }) => (result += piece));
	data.forEach(({ piece }) => (totalOtherPiece += piece));

	if (active) {
		return (
			<div className={s.tooltip}>
				<h3 className={s.tipTitle}>
					<i style={{ backgroundColor: payload[0].payload.fill }} />
					{payload[0].name
						? i18next.language === 'fr'
							? payload[0].name.replace('.', ',')
							: payload[0].name
						: '-'}{' '}
					{payload[0].name === 'Other'
						? `${normalisePieceValue(totalOtherPiece)} %`
						: ''}
				</h3>
				<ul>
					{payload[0].name === 'Other' ? (
						<Fragment>
							{mainData.map((el, i) => (
								<li key={i}>
									<span>{normalisePieceValue(el.piece)} %</span>
									{models &&
										el.concentrator_firmware &&
										`${
											models[el?.concentrator_firmware?.concentrator_model_id]
										} - `}
									{el?.concentrator_firmware
										? el.concentrator_firmware?.version
										: el.label || '-'}
								</li>
							))}
							{result ? (
								<li>
									<span>{normalisePieceValue(result)} %</span> {t('other')}
								</li>
							) : null}
						</Fragment>
					) : (
						<li>
							<span>{normalisePieceValue(payload[0].value)} %</span>
							{payload[0].name
								? i18next.language === 'fr'
									? payload[0].name.replace('.', ',')
									: payload[0].name
								: '-'}
						</li>
					)}
				</ul>
			</div>
		);
	}

	return null;
};

const SoftwareVersion = (props) => {
	const { data, modelsData = null, t } = props;
	const pieChartRef = useRef(null);

	const [isTooltipActive, setTooltipState] = useState(false);
	const [tooltipPayload, setTooltipPayload] = useState({});
	const [coordinate, setTooltipCoordinate] = useState({});

	let mainData = [];
	let otherData = [];

	if (data.length >= TOP_CATEGORIES_AMOUNT) {
		mainData = data.slice(0, 4);
	} else {
		mainData = data.slice(0, data.length);
	}

	if (data.length > TOP_CATEGORIES_AMOUNT) {
		otherData = data.slice(-(data.length - 4));
	}

	let models, modelsDesc;
	if (modelsData) {
		models = modelsDesc = {};
		modelsData.map(({ id, name }) => (models[id] = name));
		modelsData.map(({ id, description }) => (modelsDesc[id] = description));
	}

	if (otherData.length) {
		mainData.push(
			otherData.reduce(
				(acc, el) => {
					acc.piece += el.piece;
					acc.amount += el.amount;
					acc.concentrator_firmware_id.push(el.concentrator_firmware_id);

					return acc;
				},
				{
					concentrator_firmware_id: [],
					amount: 0,
					piece: 0,
					label: i18next.t('other'),
				},
			),
		);
	}

	const setViewBox = () => {
		return {
			width: pieChartRef.current.container.clientWidth,
			height: pieChartRef.current.container.clientHeight,
		};
	};

	const showTooltip = (e) => {
		setTooltipPayload(e.tooltipPayload);
		setTooltipState(true);
		setTooltipCoordinate(e.tooltipPosition);
	};

	const content = (
		<Fragment>
			<div className={s.chart} onMouseLeave={() => setTooltipState(false)}>
				<PieChart width={210} height={210} ref={pieChartRef}>
					<Pie
						data={chartData(mainData, modelsDesc)}
						dataKey='value'
						cx={100}
						cy={100}
						innerRadius={50}
						outerRadius={100}
						minAngle={2}
						onMouseEnter={showTooltip}
					>
						{mainData.map((entry, index) => (
							<Cell key={index} fill={colors[index]} stroke='none' />
						))}
					</Pie>
				</PieChart>
				{isTooltipActive ? (
					<Tooltip
						auto={true}
						viewBox={setViewBox()}
						active={isTooltipActive}
						coordinates={coordinate}
						offset={10}
						onMouseLeave={() => setTooltipState(false)}
					>
						<OtherTooltip
							t={t}
							data={otherData}
							models={modelsDesc}
							payload={tooltipPayload}
							active={isTooltipActive}
						/>
					</Tooltip>
				) : null}
			</div>

			<ul className={s.errorList}>
				{mainData.map((el, index) => (
					<li key={index} className={s.item}>
						<span
							className={s.marker}
							style={{ backgroundColor: colors[index] }}
						/>
						{modelsDesc &&
							el?.concentrator_firmware &&
							`${
								modelsDesc[el?.concentrator_firmware?.concentrator_model_id]
							} - `}
						{el?.concentrator_firmware
							? i18next.language === 'fr'
								? el?.concentrator_firmware?.version.replace('.', ',')
								: el?.concentrator_firmware?.version
							: el?.label || '-'}
					</li>
				))}
			</ul>
		</Fragment>
	);

	return (
		<div className={ps.oneThird}>
			<div className={cs(ps.panel, s.panel)}>
				<h2 className={ps.title}>{t('software_version')}</h2>

				<div className={s.inner}>
					{data.length ? (
						content
					) : (
						<div className={s.noShow}>{t('nothing_to_show')}</div>
					)}
				</div>
			</div>
		</div>
	);
};

SoftwareVersion.defaultProps = {
	data: [],
	modelsData: [],
};

SoftwareVersion.propTypes = {
	data: PropTypes.array,
	modelsData: PropTypes.array,
};

export default memo(withTranslation()(SoftwareVersion));
