import { serializer } from 'helpers';
import { unknownProvider } from '../../../containers/UsersProviders/Configs/constants';

const createFilterString = (controlsGroups = {}, isPlainObj) => {
	const {
		pinCodesByProvider,
		pinCodes,
		provider,
		errors,
		models,
		dataValues,
		FirmwareData_LV,
		FirmwareData_OV,
		firmware,
		// state, cities, concentrators
	} = controlsGroups;
	var old_version_keys = Object.values(FirmwareData_OV)
		.map((el) => el.old_version)
		.filter((el) => !!el);
	var latest_version_keys = Object.values(FirmwareData_LV)
		.map((el) => el.latest_version)
		.filter((el) => !!el);

	const errorCodes = Object.values(errors)
		.map((el) => el.code)
		.filter((el) => !!el);
	const errorValueId = Object.values(errors)
		.map((el) => el.error_id)
		.filter((el) => !!el);
	// const serialNumbers = Object.values(concentrators).map((el) => el.serial_number);
	const pinCodesByProviderIds = [];

	for (const key in pinCodesByProvider) {
		if (pinCodesByProvider && key && pinCodesByProvider[key]) {
			pinCodesByProviderIds.push(...Object.keys(pinCodesByProvider[key]));
		}
	}

	const filter = {
		searchFields: null,
		search: null,
	};
	if (Object.keys(errors).length) {
		if (errors.column_life) {
			if (filter.searchFields) {
				delete filter.searchFields;
			}
			if (filter.search) {
				delete filter.search;
			}
			errorCodes.push('column_life');
		}

		if (errors.no_errors) {
			if (filter.searchFields) {
				delete filter.searchFields;
			}
			if (filter.search) {
				delete filter.search;
			}
			errorCodes.push('no_errors');
		}
		filter['concentratorErrorRecallLastFilter'] = [{ code: errorCodes }];
		filter['codeFilter'] = [{ error_id: errorValueId }];
	}

	if (Object.keys(models).length) {
		filter.searchFields
			? (filter.searchFields['concentrator_model_id'] = 'in')
			: (filter.searchFields = { concentrator_model_id: 'in' });
		filter.search
			? (filter.search['concentrator_model_id'] = Object.keys(models))
			: (filter.search = { concentrator_model_id: Object.keys(models) });
	}

	if (Object.keys(firmware).length) {
		filter.searchFields
			? (filter.searchFields['concentrator_firmware_id'] = 'in')
			: (filter.searchFields = { concentrator_firmware_id: 'in' });
		filter.search
			? (filter.search['concentrator_firmware_id'] = Object.keys(firmware))
			: (filter.search = { concentrator_firmware_id: Object.keys(firmware) });
	}
	if (
		Object.keys(FirmwareData_LV).length ||
		Object.keys(FirmwareData_OV).length
	) {
		var keys = [],
			latest_version_keys_test = [],
			old_version_keys_test = [],
			latest_values,
			old_values;

		latest_version_keys &&
			latest_version_keys.map((data) => {
				latest_values = data.filter((element) => !isNaN(element));
				if (latest_values.length > 0) {
					latest_version_keys_test.push(latest_values);
				}
				return '';
			});
		if (latest_version_keys_test.length > 0) {
			keys.push(latest_version_keys_test);
		}
		old_version_keys &&
			old_version_keys.map((data) => {
				old_values = data.filter((element) => !isNaN(element));
				if (old_values.length > 0) {
					old_version_keys_test.push(old_values);
				}
				return '';
			});
		if (old_version_keys_test.length > 0) {
			keys.push(old_version_keys_test);
		}
		if (keys.length > 0) {
			filter.searchFields
				? (filter.searchFields['concentrator_firmware_id'] = 'in')
				: (filter.searchFields = { concentrator_firmware_id: 'in' });
			filter.search
				? (filter.search['concentrator_firmware_id'] = keys)
				: (filter.search = { concentrator_firmware_id: keys });
		}
	}
	if (Object.keys(provider).length) {
		filter.searchFields
			? (filter.searchFields['provider_id'] = 'in')
			: (filter.searchFields = { provider_id: 'in' });
		filter.search
			? (filter.search['provider_id'] = Object.keys(provider))
			: (filter.search = { provider_id: Object.keys(provider) });
	}
	if (Object.keys(dataValues).length) {
		const keys = Object.keys(dataValues);
		if (typeof keys[0] === 'string') {
			keys[0] = keys[0]
				.split(',')
				.map(Number)
				.filter((num) => num !== unknownProvider.ID);
		}
		filter.searchFields
			? (filter.searchFields['provider_id'] = 'in')
			: (filter.searchFields = { provider_id: 'in' });
		filter.search
			? (filter.search['provider_id'] = keys[0])
			: (filter.search = { provider_id: keys[0] });
	}

	if (Object.keys(pinCodes).length) {
		filter.searchFields
			? (filter.searchFields['provider_code_id'] = 'in')
			: (filter.searchFields = { provider_code_id: 'in' });
		filter.search
			? (filter.search['provider_code_id'] = Object.keys(pinCodes))
			: (filter.search = { provider_code_id: Object.keys(pinCodes) });
	}

	if (pinCodesByProviderIds.length) {
		filter.searchFields
			? (filter.searchFields['provider_code_id'] = 'in')
			: (filter.searchFields = { provider_code_id: 'in' });
		filter.search
			? (filter.search['provider_code_id'] = pinCodesByProviderIds)
			: (filter.search = { provider_code_id: pinCodesByProviderIds });
	}

	if (isPlainObj) {
		return filter;
	}

	return serializer(filter);
};

export default createFilterString;
