import React from 'react';
import SelectComponent from '../UI/SelectComponent';
import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import i18next from 'i18next';

function Pagination(props) {
	const {
		t,
		options,
		pageSize,
		handlePageSize,
		totalCount,
		currentPage,
		onPageChange,
	} = props;
	const pageNumbers = [];
	const pagesToShow = 5;
	const pageCount = Math.ceil(totalCount / pageSize);

	for (let i = 1; i <= pageCount; i++) {
		pageNumbers.push(i);
	}
	// creating set of pages to show
	const renderPageNumbers = () => {
		const pageSet = Math.ceil(currentPage / pagesToShow);
		const startPage = (pageSet - 1) * pagesToShow + 1;
		const endPage = Math.min(startPage + pagesToShow - 1, pageCount);
		return pageNumbers.slice(startPage - 1, endPage).map((page) => (
			<button
				key={page}
				onClick={() => onPageChange(page)}
				className={page === currentPage ? s.activePageNumber : s.pageNumber}
			>
				{page}
			</button>
		));
	};
	// click handler for pagination arrow buttons
	const handlePreviousClick = () => {
		if (currentPage > 1) {
			onPageChange(currentPage - 1);
		}
	};

	const handleNextClick = () => {
		if (currentPage < pageCount) {
			onPageChange(currentPage + 1);
		}
	};

	const handleFirstClick = () => {
		onPageChange(1);
	};

	const handleLastClick = () => {
		onPageChange(pageCount);
	};
	return (
		<div className={s.paginationWrapper}>
			<div className={s.showPagination}>
				<span className={s.showTypography}>{t('show')}</span>
				<SelectComponent
					key='noOfItemsCount'
					isMulti={false}
					options={options}
					value={pageSize}
					onChange={handlePageSize}
					placeholder='Select'
					selectedLabel={pageSize}
					className={s.widthManagement}
				/>
				<span className={s.showTypography}>{t('entries')}</span>
			</div>
			<div className={s.centerPagination}>
				<span className={s.showTypography}>{t('showing')}</span>
				<span className={s.showCounts}>
					{currentPage !== 1 ? (currentPage - 1) * pageSize + 1 : currentPage}-
					{currentPage * pageSize < totalCount
						? currentPage * pageSize
						: totalCount}
				</span>{' '}
				<span className={s.showTypography}>{t('of')}</span>{' '}
				<span className={s.showCounts}>{totalCount}</span>{' '}
				<span className={s.showTypography}>{t('results')}</span>
			</div>
			<div className={s.pagination}>
				<div className={s.lower_pagination}>
					<button
						data-testid='firstButton'
						className={s.firstLastButton}
						onClick={handleFirstClick}
						disabled={currentPage === 1}
						data-tip={i18next.t('first_page')}
					>
						<svg
							width='14'
							height='12'
							viewBox='0 0 14 12'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M6 12L0 6L6 0L7.4 1.4L2.825 6L7.4 10.6L6 12ZM12.6 12L6.6 6L12.6 0L14 1.4L9.425 6L14 10.6L12.6 12Z'
								fill='#333437'
							/>
						</svg>
					</button>
					<button
						data-testid='previousButton'
						className={s.firstLastButton}
						onClick={handlePreviousClick}
						disabled={currentPage === 1}
						data-tip={i18next.t('previous_page')}
					>
						<svg
							width='8'
							height='12'
							viewBox='0 0 8 12'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M6 12L0 6L6 0L7.4 1.4L2.8 6L7.4 10.6L6 12Z'
								fill='black'
							/>
						</svg>
					</button>
					{renderPageNumbers()}
					<button
						data-testid='nextButton'
						className={s.firstLastButton}
						onClick={handleNextClick}
						disabled={currentPage === pageCount}
						data-tip={i18next.t('next_page')}
					>
						<svg
							width='8'
							height='12'
							viewBox='0 0 8 12'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M4.6 6L0 1.4L1.4 0L7.4 6L1.4 12L0 10.6L4.6 6Z'
								fill='black'
							/>
						</svg>
					</button>
					<button
						data-testid='lastButton'
						className={s.firstLastButton}
						onClick={handleLastClick}
						disabled={currentPage === pageCount}
						data-tip={i18next.t('last_page')}
					>
						<svg
							width='14'
							height='12'
							viewBox='0 0 14 12'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M4.575 6L0 1.4L1.4 0L7.4 6L1.4 12L0 10.6L4.575 6ZM11.175 6L6.6 1.4L8 0L14 6L8 12L6.6 10.6L11.175 6Z'
								fill='#333437'
							/>
						</svg>
					</button>
					<ReactTooltip
						className={s.tooltip}
						place='top'
						type='light'
						effect='solid'
					/>
				</div>
			</div>
		</div>
	);
}
const mapStateToProps = () => {
	return {};
};
const mapDispatchToProps = () => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(Pagination));
