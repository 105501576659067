import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
import sOld from './style.module.scss';
import sLatest from './newStyle.module.scss';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { format } from 'date-fns';
registerLocale('us', enUS);
registerLocale('fr', fr);
registerLocale('es', es);
class TableDateFilter extends Component {
	state = {
		displayText: i18next.t('all'),
		isOpen: false,
	};

	componentDidUpdate(prevProps) {
		const { config, clear } = this.props;

		if (prevProps.config !== config) {
			clear();
		}
	}

	getCurrentValues = () => {
		let { singleDate, fromDate, toDate, t, config } = this.props;
		let date = [];
		if (singleDate) {
			date.push(format(singleDate, 'yyyy-MM-dd'));
		} else if (fromDate && toDate) {
			date.push(format(fromDate, 'yyyy-MM-dd'));
			date.push(format(toDate, 'yyyy-MM-dd'));
		}

		return date.join(' - ') || t(config.defaultValue);
	};

	onSelectClick = () => {
		this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
	};

	onItemClick = (el) => {
		const { config, singleDate, fromDate, toDate, clearSelect, itemClicked } =
			this.props;
		const ifCheckDate = singleDate || (fromDate && toDate);

		itemClicked(el);

		if (ifCheckDate) clearSelect(config);
	};

	handleClickOutside = () => {
		this.setState({ isOpen: false });
	};

	render() {
		const { config, className, singleChange, fromChange, toChange, t } =
			this.props;
		let s = this.props.newDesign ? sLatest : sOld;

		const classes = [s.filterWrap, className];

		if (this.state.isOpen) {
			classes.push(s.open);
		}
		return (
			<div className={classes.join(' ')}>
				<div className={s.filterSelect} onClick={this.onSelectClick}>
					{this.getCurrentValues()}
				</div>

				{this.state.isOpen && (
					<div className={s.filterList}>
						<ul className={s.list}>
							{config.data &&
								config.data.map((el) => {
									const isActive = this.props.checked === el.id;
									let inner;

									switch (el.type) {
										case 'single':
											inner = (
												<DatePicker
													inline
													calendarClassName={s.datepicker}
													selected={this.props.singleDate}
													onChange={(data) => singleChange(data, config)}
													locale={i18next.language}
													dateFormat='D MMM, YYYY'
												/>
											);
											break;
										case 'period':
											inner = (
												<div className={s.row}>
													<div className={s.col}>
														<span className={s.dpLabel}>{t('from')}</span>
														<DatePicker
															inline
															calendarClassName={s.datepicker}
															selected={this.props.fromDate}
															startDate={this.props.fromDate}
															endDate={this.props.toDate}
															maxDate={this.props.toDate}
															onChange={(data) => fromChange(data, config)}
															locale={i18next.language}
															dateFormat='D MMM, YYYY'
														/>
													</div>
													<div className={s.col}>
														<span className={s.dpLabel}>{t('to')}</span>
														<DatePicker
															inline
															calendarClassName={s.datepicker}
															selected={this.props.toDate}
															startDate={this.props.fromDate}
															endDate={this.props.toDate}
															minDate={this.props.fromDate}
															onChange={(data) => toChange(data, config)}
															locale={i18next.language}
															dateFormat='D MMM, YYYY'
														/>
													</div>
												</div>
											);
											break;
										default:
											break;
									}

									return (
										<li key={el.id} className={isActive ? s.active : ''}>
											<div
												className={s.label}
												onClick={() => this.onItemClick(el)}
											>
												{t(el.label)}
											</div>
											<div className={s.inner}>{inner}</div>
										</li>
									);
								})}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

TableDateFilter.defaultProps = {
	config: {},
	checked: 1,
	singleChange: () => {},
	fromChange: () => {},
	toChange: () => {},
	clearSelect: () => {},
	itemClicked: () => {},
};

TableDateFilter.propTypes = {
	config: PropTypes.object,
	checked: PropTypes.number,
	singleChange: PropTypes.func,
	fromChange: PropTypes.func,
	toChange: PropTypes.func,
	clearSelect: PropTypes.func,
	itemClicked: PropTypes.func,
};

export default withTranslation()(onClickOutside(TableDateFilter));
