const checkValidity = (value, rules) => {
	let isValid = true;

	if (!rules) {
		return true;
	}

	if (rules.required) {
		isValid = value.trim() !== '' && isValid;
	}

	if (rules.minLength) {
		isValid = value.length >= rules.minLength && isValid;
	}

	if (rules.maxLength) {
		isValid = value.length <= rules.maxLength && isValid;
	}

	if (!rules.required && rules.maxLength) {
		isValid = !value.length || (value.length <= rules.maxLength && isValid);
	}
	if (!rules.required && rules.minLength) {
		isValid = !value.length || (value.length >= rules.minLength && isValid);
	}

	if (rules.isEmail) {
		const pattern =
			/^[a-zA-Z0-9]+(?:[._%+-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
		isValid = pattern.test(value) && isValid;
	}

	if (rules.isNumeric) {
		const pattern = /^\d+$/;

		isValid = pattern.test(value) && isValid;
	}

	if (rules.isPhone) {
		const pattern = /^\d{7,15}$/;

		isValid = pattern.test(value) && isValid;
	}

	if (rules.isurl) {
		const pattern =
			/^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm;
		isValid = (pattern.test(value) || !value.length) && isValid;
	}

	if (rules.isfax) {
		const pattern = /^\+\d{1,}-\d{1,}-\d{1,}-\d{1,}$/;

		isValid = (pattern.test(value) || !value.length) && isValid;
	}
	if (rules.isinfo) {
		const pattern =
			/^(?! )(?!.* {2})(?!^\d+$)(?![!@#$%^&*()=_+{}[\]|\\:;"'<>,.?/~`]+$)[a-zA-Z0-9!@#$%^&*()=_+{}[\]|\\:;"'<>,.?/~` -]{1,100}$/;

		isValid = (pattern.test(value) || !value.length) && isValid;
	}

	if (rules.isaddress) {
		const pattern = /^[a-zA-Z0-9\s,._()-]+$/;

		isValid = pattern.test(value) && isValid;
	}
	if (rules.isaddressoptional) {
		const pattern = /^[a-zA-Z0-9\s,._()-]+$/;

		isValid = (pattern.test(value) || !value.length) && isValid;
	}

	if (rules.isname) {
		const pattern = /^(?! )[a-zA-Z\s]{1,255}$/;

		isValid = pattern.test(value) && isValid;
	}

	if (rules.isuserName) {
		const pattern = /^(?! )[a-zA-Z0-9\s]{1,255}$/;

		isValid = pattern.test(value) && isValid;
	}

	// validation for canada postal code
	if (rules.isCanadianPostal) {
		const pattern =
			/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
		isValid = pattern.test(value) && isValid;
	}
	// validation for US postal code
	if (rules.isUSPostal) {
		const pattern = /^\d{5,5}$/;

		isValid = pattern.test(value) && isValid;
	}

	// validation for other countries postal code
	if (rules.postal) {
		const pattern = /^\d{1,6}$/;

		isValid = pattern.test(value) && isValid;
	}

	if (rules.iscities) {
		const pattern = /^(?! )(?!.*[ -]{2})[A-Za-z -]{1,100}$/;
		isValid = pattern.test(value) && isValid;
	}

	if (rules.isAlphaNumeric) {
		const pattern = /^[a-zA-Z0-9]+$/;
		isValid = (pattern.test(value) || !value.length) && isValid;
	}

	return isValid;
};

export default checkValidity;
