import React, { PureComponent } from 'react';
import BlockUser from '../BlockUser';
import { Button, Spinner, Select, Input } from 'components';
import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import { AuxWrap } from 'hoc';
import { EditIcon as Edit, Close } from 'components/Icons';

import initialStateCreator from './initialStateCreator';

import s from './style.module.scss';
import i18next from 'i18next';
import { handleLabel } from 'helpers';

class UserDetails extends PureComponent {
	constructor(props) {
		super(props);
		this.state = initialStateCreator(props);
	}

	componentDidMount() {
		logger('[UserDetails] componentDidMount');

		this.getUserDetails();
	}

	getUserDetails = (callback) => {
		const { id, user_id } = this.props.modalData;

		axios
			.get(api.users.index + `/${id || user_id}`, {
				params: { with: ['roles', 'provider'] },
			})
			.then((response) => {
				logger('[UserDetails] Get user details');

				this.setState((prevState) => {
					const { data } = response.data;
					const { controls, details } = prevState;

					controls.role = {
						checked: { [data.roles[0].id]: data.roles[0] },
					};
					controls.provider = {
						checked: { [data.provider_id]: data.provider },
					};

					if (details && details.provider_id !== data.provider_id) {
						return { controls, details: data, step: 'role' };
					}

					return {
						controls,
						details: data,
						selectedRole: data?.roles?.[0]?.label,
					};
				});

				callback && callback();
			})
			.catch(() => {
				logger('[UserDetails] Get user details fail');
			});
	};

	onItemClick = (data, type) => {
		if (data) {
			if (data.label) {
				this.setState({ userbuttonEnabled: false });
			}
			this.setState((prevState) => {
				const controls = { ...prevState.controls };

				controls[type].checked = { [data.id]: data };

				return { controls: controls };
			});
		} else {
			this.setState({ userbuttonEnabled: true });
		}
	};

	mainView = () => {
		const { details } = this.state;
		const {
			type,
			rowIndex,
			modalData,
			changeUserStatus,
			changeSearchUsersStatus,
		} = this.props;
		const {
			user_id,
			id,
			status = 0,
			user_status = 0,
			role_label,
			roles,
		} = modalData;
		// const roleLabel = details && details.roles[0].label;
		// const userStatus = status || user_status;
		const userStatus = details ? details.status : status || user_status;

		// let isBlocked = null;
		let fields = <Spinner className={s.spinner} />;

		const setStatusHandler = () => {
			const args = {
				id: id || user_id,
				index: rowIndex,
				label: role_label || roles[0].label,
				status: userStatus ? 0 : 1,
				callback: this.getUserDetails,
			};

			if (!args.status) {
				return this.setState({ blockUserPopup: true });
			}

			switch (type) {
				case 'search':
					changeSearchUsersStatus(args);
					break;
				default:
					changeUserStatus(args);
					break;
			}
		};

		const changeStep = (step) =>
			this.setState((prevState) => {
				const { controls, details } = prevState;

				controls.role = {
					checked: { [details.roles[0].id]: details.roles[0] },
				};
				controls.provider = {
					checked: { [details.provider_id]: details.provider },
				};

				return {
					controls,
					step: step,
				};
			});

		// if (!userStatus) {
		// 	isBlocked = <span className={s.blocked}>{i18next.t("blocked")}</span>;
		// }

		if (details) {
			const config = {
				displayKey: 'label',
				label: {
					path: 'label',
				},
			};
			fields = (
				<AuxWrap>
					<div className={s.containerEdit}>
						<div className={s.field}>
							<span className={s.label}>{i18next.t('username')}</span>
							<div className={s.value}>
								<Input
									className={s.showName}
									elementType={'input'}
									elementConfig={{
										type: 'text',
										placeholder: i18next.t('username'),
									}}
									value={details?.name || ''}
									disabled={true}
									newDesign={true}
								/>
							</div>
						</div>
					</div>
					<div className={s.containerEdit}>
						<div className={s.field}>
							<span className={s.label}>{i18next.t('user_role')}</span>
							<div className={s.value}>
								{/* {roleLabel} */}
								<Select
									type={'role'}
									className={s.selectIconGap}
									config={config}
									data={[]}
									newDesign={true}
									defaultValue={this.state.selectedRole || ''}
									isDisabled={true}
								/>
								{!!userStatus && (
									<Edit
										className={s.editBtn}
										onClick={() => changeStep('role')}
									/>
								)}
							</div>
						</div>
					</div>
				</AuxWrap>
			);
		}

		return (
			<AuxWrap>
				{/* <h2 className={s.title}>
					{modalData.email || modalData.user_email}
					{isBlocked}
				</h2> */}
				<div className={s.titleAlign}>
					<div className={s.iconTitle}>
						<span className={s.titleTypography}>
							{i18next.t('editUser')} {"'"}
							{modalData.email || modalData.user_email}
							{"'"}
						</span>
					</div>
					<Close
						className={s.closeBtn}
						width={36}
						height={36}
						fill='#6D6D6D'
						onClick={this.props.close}
					/>
				</div>
				<div className={s.contentContainer}>
					{fields}

					<div className={[s.buttons, s.mainBtn].join(' ')}>
						{/* <span
							className={[s.btn, !userStatus && s.unblock].join(" ")}
							onClick={setStatusHandler}
						>
							{userStatus ? i18next.t("block_user") : i18next.t("unblock_user")}
						</span> */}
						<Button
							className={[s.btn, !userStatus && s.unblock].join(' ')}
							btnType='type-16'
							clicked={setStatusHandler}
						>
							{userStatus ? i18next.t('block') : i18next.t('unBlock')}
						</Button>
					</div>
				</div>
			</AuxWrap>
		);
	};

	editRoleView = () => {
		const { modalData, rolesData, changeUserRole } = this.props;
		const { controls, userbuttonEnabled } = this.state;
		const userId = modalData.id || modalData.user_id;
		// const roles = rolesData.filter(
		// 	(el) => el.is_inogen === details.provider.is_inogen
		// );
		const UserRoles = rolesData?.length > 0 ? rolesData : [];
		// roles &&
		// roles.length > 0 &&
		// roles.filter((data) => {
		// 	return data.name && data.name !== i18next.t("firmware_admin");
		// });
		const config = {
			displayKey: 'label',
			label: {
				path: 'label',
			},
		};

		const saveHandler = () => {
			let selectedRole = Object.values(controls?.role?.checked)[0]?.label;
			const args = {
				id: userId,
				data: {
					role_ids: [Object.values(controls?.role?.checked)[0]?.id],
				},
				oldRole: modalData?.role_label || modalData?.roles[0]?.label,
				newRole: Object.values(controls?.role?.checked)[0]?.label,
				success: () =>
					this.setState(initialStateCreator(selectedRole), this.getUserDetails),
			};

			changeUserRole(args);
		};

		return (
			<AuxWrap>
				{/* <h2 className={s.title}>{i18next.t("edit_user_role")}</h2>
				<div className={s.subText}>
					{modalData.email || modalData.user_email}
				</div> */}
				<div className={s.titleAlign}>
					<div className={s.iconTitle}>
						<span className={s.titleTypography}>
							{i18next.t('editUser')} {"'"}
							{modalData.email || modalData.user_email}
							{"'"}
						</span>
					</div>
					<Close
						className={s.closeBtn}
						width={36}
						height={36}
						fill='#6D6D6D'
						onClick={this.props.close}
					/>
				</div>
				<div className={s.contentContainer}>
					<div className={s.containerEdit}>
						<div className={s.selectField}>
							<div className={s.selectHead}>
								<span>{i18next.t('user_role')}</span>
							</div>
							<Select
								type={'role'}
								config={config}
								data={handleLabel(UserRoles)}
								checked={this.state.controls.role.checked}
								isOpen={this.state.controls.role.isOpen}
								onItemClick={this.onItemClick}
								newDesign={true}
								// defaultValue={this.state.selectedRole || ''}
							/>
						</div>
					</div>
				</div>
				<div className={s.buttons}>
					<Button
						btnType='type-22'
						className={s.cancel}
						clicked={() =>
							this.setState(
								initialStateCreator({ selectedRole: this.state.selectedRole }),
							)
						}
					>
						{i18next.t('cancel')}
					</Button>
					<Button
						btnType='type-21'
						disabled={userbuttonEnabled}
						clicked={saveHandler}
					>
						{i18next.t('save')}
					</Button>
				</div>
			</AuxWrap>
		);
	};

	renderSteps = () => {
		let step = this.mainView();

		switch (this.state.step) {
			case 'role':
				step = this.editRoleView();
				break;
			default:
				break;
		}

		return step;
	};

	render() {
		const { details, blockUserPopup } = this.state;
		const {
			type,
			rowIndex,
			modalData,
			changeUserStatus,
			changeSearchUsersStatus,
		} = this.props;
		const {
			user_id,
			id,
			status = 0,
			user_status = 0,
			role_label,
			roles,
		} = modalData;

		const userStatus = details ? details.status : status || user_status;

		const setStatusHandler = () => {
			const args = {
				id: id || user_id,
				index: rowIndex,
				label: role_label || roles[0].label,
				status: userStatus ? 0 : 1,
				callback: () => {
					this.getUserDetails(() => this.setState({ blockUserPopup: false }));
				},
			};

			switch (type) {
				case 'search':
					changeSearchUsersStatus(args);
					break;
				default:
					changeUserStatus(args);
					break;
			}
		};

		if (blockUserPopup) {
			return (
				<BlockUser
					email={modalData.email || modalData.user_email}
					approveHandler={setStatusHandler}
					cancelHandler={() => this.setState({ blockUserPopup: false })}
				/>
			);
		}

		return this.renderSteps();
	}
}

export default UserDetails;
