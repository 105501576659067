import React from 'react';
import PropTypes from 'prop-types';
import { Select, Input } from 'components';
import { AuxWrap } from 'hoc';
import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import ReactSelect, { components } from 'react-select';

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<svg
				width='10'
				height='5'
				viewBox='0 0 10 5'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path d='M0 0L5 5L10 0H0Z' fill='#999999' />
			</svg>
		</components.DropdownIndicator>
	);
};
const customStyles = {
	control: (provided) => ({
		...provided,
	}),
	indicatorSeparator: (provider) => ({
		...provider,
		marginTop: '0px',
		marginBottom: '0px',
	}),
};

const ProviderFields = (props) => {
	const {
		data,
		t,
		state,
		inputChanged,
		inputBlur,
		selectClick,
		selectItemClick,
		countries_data,
		Country,
		country_data,
		state_data,
		State,
		selected_state,
		code,
	} = props;
	const config = {
		displayKey: 'name',
		label: {
			path: 'name',
		},
	};
	let provider = (
		<div className={s.selectField}>
			<div className={s.selectHead}>
				<span className={s.subTitleTypography}>
					{t('provider')} <span className={s.mandatory}>{'*'}</span>
				</span>
			</div>
			<div></div>
			<Select
				type={'provider'}
				config={config}
				data={data}
				checked={state.controls && state.controls.provider.checked}
				isOpen={state.controls && state.controls.provider.isOpen}
				clicked={selectClick}
				onItemClick={selectItemClick}
				newDesign={true}
			/>
		</div>
	);
	if (state.providerView === 'new') {
		const { newProvider } = state.controls;
		const address1Validation = () => {
			const length = newProvider.address_1.value.length;
			const maxLength = newProvider.address_1.validation.maxLength;
			return (
				<span className={s.alignCountValidation}>
					{length}/
					<span
						className={
							length >= maxLength ? s.red : '' + length > 0 ? s.green : ''
						}
					>
						{maxLength - length}
					</span>
				</span>
			);
		};
		const address2Validation = () => {
			const length = newProvider.address_2.value.length;
			const maxLength = newProvider.address_2.validation.maxLength;
			return (
				<span className={s.alignCountValidation}>
					{length}/
					<span
						className={
							length >= maxLength ? s.red : '' + length > 0 ? s.green : ''
						}
					>
						{maxLength - length}
					</span>
				</span>
			);
		};
		const infoValidation = () => {
			const length = newProvider.info.value.length;
			const maxLength = newProvider.info.validation.maxLength;
			return (
				<span>
					{length}/
					<span
						className={
							length >= maxLength ? s.red : '' + length > 0 ? s.green : ''
						}
					>
						{maxLength - length}
					</span>
				</span>
			);
		};

		provider = (
			<AuxWrap>
				<div className={s.row}>
					<div className={s.col}>
						<div className={s.selectField}>
							<div className={s.selectHead}>
								<span>
									{t('provider_name')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</span>
							</div>
							<Input
								className={newProvider.name.className}
								elementType={newProvider.name.elementType}
								elementConfig={newProvider.name.elementConfig}
								value={newProvider.name.value}
								invalid={!newProvider.name.valid}
								invalidMsg={t(newProvider.name.errorMsg)}
								touched={newProvider.name.touched}
								changed={(event) => inputChanged(event, 'name', 'newProvider')}
								onBlur={(event) => inputBlur(event, 'name', 'newProvider')}
								newDesign={true}
							/>
						</div>
					</div>
					<div className={s.col}>
						<div className={s.selectField}>
							<div className={s.selectHead}>
								<span>{t('customer_id')} </span>
							</div>
							<Input
								className={newProvider.crm_customer_id.className}
								elementType={newProvider.crm_customer_id.elementType}
								elementConfig={newProvider.crm_customer_id.elementConfig}
								value={newProvider.crm_customer_id.value}
								invalid={!newProvider.crm_customer_id.valid}
								invalidMsg={t(newProvider.crm_customer_id.errorMsg)}
								touched={newProvider.crm_customer_id.touched}
								changed={(event) =>
									inputChanged(event, 'crm_customer_id', 'newProvider')
								}
								onBlur={(event) =>
									inputBlur(event, 'crm_customer_id', 'newProvider')
								}
								newDesign={true}
							/>
						</div>
					</div>
				</div>
				<div className={s.row}>
					<div className={s.col}>
						<div className={s.selectField}>
							<div className={s.selectHead}>
								<span>
									{t('provider_admin')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</span>
							</div>
							<Input
								className={newProvider.provider_admin.className}
								elementType={newProvider.provider_admin.elementType}
								elementConfig={newProvider.provider_admin.elementConfig}
								value={newProvider.provider_admin.value}
								invalid={!newProvider.provider_admin.valid}
								invalidMsg={t(newProvider.provider_admin.errorMsg)}
								touched={newProvider.provider_admin.touched}
								changed={(event) =>
									inputChanged(event, 'provider_admin', 'newProvider')
								}
								onBlur={(event) =>
									inputBlur(event, 'provider_admin', 'newProvider')
								}
								newDesign={true}
							/>
						</div>
					</div>
					<div className={s.col}>
						<div className={s.selectField}>
							<div className={s.selectHead}>
								<span>
									{t('provider_email')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</span>
							</div>
							<Input
								className={newProvider.email_address.className}
								elementType={newProvider.email_address.elementType}
								elementConfig={newProvider.email_address.elementConfig}
								value={newProvider.email_address.value}
								invalid={!newProvider.email_address.valid}
								invalidMsg={t(newProvider.email_address.errorMsg)}
								touched={newProvider.email_address.touched}
								changed={(event) =>
									inputChanged(event, 'email_address', 'newProvider')
								}
								onBlur={(event) =>
									inputBlur(event, 'email_address', 'newProvider')
								}
								newDesign={true}
							/>
						</div>
					</div>
				</div>
				<div className={s.row}>
					<div className={s.col}>
						<div className={s.selectHead}>
							<span>
								{t('country')} <span className={s.mandatory}>{'*'}</span>
							</span>
						</div>
						<ReactSelect
							components={{ DropdownIndicator }}
							placeholder={t('selectCountry')}
							options={countries_data}
							getOptionLabel={(options) => {
								return options['name'];
							}}
							getOptionValue={(options) => {
								return options['name'];
							}}
							value={country_data}
							onChange={Country}
							className={s.dropdown}
							styles={customStyles}
						/>
					</div>
					<div className={s.col}>
						<div className={s.selectHead}>
							<span>
								{t('state')} <span className={s.mandatory}>{'*'}</span>
							</span>
						</div>
						<ReactSelect
							components={{ DropdownIndicator }}
							placeholder={t('selectState')}
							options={selected_state}
							getOptionLabel={(options) => {
								return options['name'];
							}}
							getOptionValue={(options) => {
								return options['name'];
							}}
							value={state_data}
							onChange={State}
							className={s.dropdown}
							styles={customStyles}
						/>
					</div>
				</div>
				<div className={s.row}>
					<div className={s.col}>
						<div className={s.selectHead}></div>
						<div className={s.selectHead}>
							<span>
								{t('city')} <span className={s.mandatory}>{'*'}</span>
							</span>
						</div>
						<Input
							className={newProvider.city.className}
							elementType={newProvider.city.elementType}
							elementConfig={newProvider.city.elementConfig}
							value={newProvider.city.value}
							invalid={!newProvider.city.valid}
							invalidMsg={t(newProvider.city.errorMsg)}
							touched={newProvider.city.touched}
							changed={(event) => inputChanged(event, 'city', 'newProvider')}
							onBlur={(event) => inputBlur(event, 'city', 'newProvider')}
							newDesign={true}
						/>
					</div>
					<div className={s.col}>
						<div className={s.selectHead}></div>
						<div className={s.selectHead}>
							<span>
								{t('postal_code')} <span className={s.mandatory}>{'*'}</span>
							</span>
						</div>
						<Input
							className={newProvider.postal_code.className}
							elementType={newProvider.postal_code.elementType}
							elementConfig={newProvider.postal_code.elementConfig}
							value={newProvider.postal_code.value}
							invalid={!newProvider.postal_code.valid}
							invalidMsg={t(newProvider.postal_code.errorMsg)}
							touched={newProvider.postal_code.touched}
							changed={(event) =>
								inputChanged(event, 'postal_code', 'newProvider')
							}
							onBlur={(event) => inputBlur(event, 'postal_code', 'newProvider')}
							newDesign={true}
						/>
					</div>
				</div>
				<div className={s.rowEditAddress}>
					<div className={s.col1}>
						{/* <div className={s.spaceField}></div> */}
						<div>
							<div className={s.selectHead}>
								<span>
									{t('provider_address')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</span>
							</div>
							<Input
								className={newProvider.address_1.className}
								elementType={newProvider.address_1.elementType}
								elementConfig={newProvider.address_1.elementConfig}
								value={newProvider.address_1.value}
								invalid={!newProvider.address_1.valid}
								invalidMsg={t(newProvider.address_1.errorMsg)}
								maxlength={newProvider.address_1.validation.maxLength}
								touched={newProvider.address_1.touched}
								changed={(event) =>
									inputChanged(event, 'address_1', 'newProvider')
								}
								onBlur={(event) => inputBlur(event, 'address_1', 'newProvider')}
								newDesign={true}
							/>
							{/* <div className={s.alignCountValidation}> */}
							{address1Validation()}
							{/* </div> */}
						</div>
					</div>
					<div className={s.col1}>
						<div>
							<Input
								className={newProvider.address_2.className}
								elementType={newProvider.address_2.elementType}
								elementConfig={newProvider.address_2.elementConfig}
								value={newProvider.address_2.value}
								invalid={!newProvider.address_2.valid}
								invalidMsg={t(newProvider.address_2.errorMsg)}
								maxlength={newProvider.address_2.validation.maxLength}
								touched={newProvider.address_2.touched}
								changed={(event) =>
									inputChanged(event, 'address_2', 'newProvider')
								}
								onBlur={(event) => inputBlur(event, 'address_2', 'newProvider')}
								newDesign={true}
							/>
							{/* <span className={s.alignCountValidation}> */}
							{address2Validation()}
							{/* </span> */}
						</div>
					</div>
				</div>
				<div className={s.row}>
					<div className={s.col}>
						<div className={s.selectField}>
							<div className={s.selectHead}>
								<span>
									{t('provider_phone')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</span>
							</div>
							<ul className={s.phoneField}>
								<li>
									<div className={s.inputBlock}>{code}</div>
								</li>
								<li>
									<Input
										className={newProvider.phone_number.className}
										elementType={newProvider.phone_number.elementType}
										elementConfig={newProvider.phone_number.elementConfig}
										value={newProvider.phone_number.value}
										invalid={!newProvider.phone_number.valid}
										invalidMsg={t(newProvider.phone_number.errorMsg)}
										touched={newProvider.phone_number.touched}
										changed={(event) =>
											inputChanged(event, 'phone_number', 'newProvider')
										}
										onBlur={(event) =>
											inputBlur(event, 'phone_number', 'newProvider')
										}
										newDesign={true}
									/>
								</li>
							</ul>
						</div>
					</div>
					<div className={s.col}>
						<div className={s.selectField}>
							<div className={s.selectHead}>
								<span>
									{t('customer_care_number')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</span>
							</div>
							<ul className={s.phoneField}>
								<li>
									<div className={s.inputBlock}>{code}</div>
								</li>
								<li>
									<Input
										className={newProvider.customer_care_number.className}
										elementType={newProvider.customer_care_number.elementType}
										elementConfig={
											newProvider.customer_care_number.elementConfig
										}
										value={newProvider.customer_care_number.value}
										invalid={!newProvider.customer_care_number.valid}
										invalidMsg={t(newProvider.customer_care_number.errorMsg)}
										touched={newProvider.customer_care_number.touched}
										changed={(event) =>
											inputChanged(event, 'customer_care_number', 'newProvider')
										}
										onBlur={(event) =>
											inputBlur(event, 'customer_care_number', 'newProvider')
										}
										newDesign={true}
									/>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className={s.row}>
					<div className={s.col}>
						<div className={s.selectField}>
							<div className={s.selectHead}>
								<span>{t('provider_fax')}</span>
							</div>
							<Input
								className={newProvider.fax.className}
								elementType={newProvider.fax.elementType}
								elementConfig={newProvider.fax.elementConfig}
								value={newProvider.fax.value ? newProvider.fax.value : ''}
								invalid={!newProvider.fax.valid}
								invalidMsg={t(newProvider.fax.errorMsg)}
								touched={newProvider.fax.touched}
								changed={(event) => inputChanged(event, 'fax', 'newProvider')}
								onBlur={(event) => inputBlur(event, 'fax', 'newProvider')}
								newDesign={true}
							/>
						</div>
					</div>
					<div className={s.col}>
						<div className={s.selectField}>
							<div className={s.selectHead}>
								<span>{t('weburl')}</span>
							</div>
							<Input
								className={newProvider.website_url.className}
								elementType={newProvider.website_url.elementType}
								elementConfig={newProvider.website_url.elementConfig}
								value={
									newProvider.website_url.value
										? newProvider.website_url.value
										: ''
								}
								invalid={!newProvider.website_url.valid}
								invalidMsg={t(newProvider.website_url.errorMsg)}
								touched={newProvider.website_url.touched}
								changed={(event) =>
									inputChanged(event, 'website_url', 'newProvider')
								}
								onBlur={(event) =>
									inputBlur(event, 'website_url', 'newProvider')
								}
								newDesign={true}
							/>
						</div>
					</div>
				</div>
				<div className={s.row}>
					<div className={s.col1}>
						<div className={s.selectField}>
							<div className={s.selectHead}>
								<span>{t('info_optional')}</span>
							</div>
							<Input
								className={newProvider.info.className}
								elementType={newProvider.info.elementType}
								elementConfig={newProvider.info.elementConfig}
								value={newProvider.info.value ? newProvider.info.value : ''}
								invalid={!newProvider.info.valid}
								rows={1}
								invalidMsg={t(newProvider.info.errorMsg)}
								maxlength={newProvider.info.validation.maxLength}
								touched={newProvider.info.touched}
								changed={(event) => inputChanged(event, 'info', 'newProvider')}
								onBlur={(event) => inputBlur(event, 'info', 'newProvider')}
								newDesign={true}
							/>
							<div className={s.alignCountValidation}>{infoValidation()}</div>
						</div>
					</div>
				</div>
			</AuxWrap>
		);
	}
	return provider;
};

ProviderFields.defaultProps = {
	state: {},
	changeView: () => {},
	addProviderAdmin: () => {},
	inputBlur: () => {},
};
ProviderFields.propTypes = {
	state: PropTypes.object,
	changeView: PropTypes.func,
	addProviderAdmin: PropTypes.func,
	inputBlur: PropTypes.func,
};
export default withTranslation()(ProviderFields);
