import React from 'react';
import T from 'prop-types';

const List = (props) => (
	<svg {...props} className={props.className} data-testid='list_view_icon'>
		<path
			fillRule='nonzero'
			d='M8 6h12a1 1 0 0 1 0 2H8a1 1 0 1 1 0-2zm0 5h12a1 1 0 0 1 0 2H8a1 1 0 0 1 0-2zm0 5h12a1 1 0 0 1 0 2H8a1 1 0 0 1 0-2zM4 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0 5a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0 5a1 1 0 1 1 0 2 1 1 0 0 1 0-2z'
		/>
	</svg>
);

List.defaultProps = {
	fill: '#999',
	width: 36,
	height: 36,
	viewBox: '0 0 24 24',
};

List.propTypes = {
	className: T.string,
};

export default List;
