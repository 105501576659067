import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './assets/locales/en/translation';
import translationFR from './assets/locales/fr/translation';
import translationSP from './assets/locales/es/translation';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/es';

const resources = {
	en: {
		translation: translationEN,
	},
	fr: {
		translation: translationFR,
	},
	es: {
		translation: translationSP,
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		preload: 'all',
		whitelist: ['fr', 'en', 'es'],
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		keySeparator: false,
		nsSeparator: false,
	});

i18n.on('languageChanged', function (lng) {
	moment.locale(lng);
});

export const updateTranslations = (translations) => {
	i18n.addResourceBundle('en', 'translation', translations.en, true, true);
	i18n.addResourceBundle('fr', 'translation', translations.fr, true, true);
	i18n.addResourceBundle('es', 'translation', translations.es, true, true);
};

export default i18n;
