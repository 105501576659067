export const getUsersOptions = (value) => ({
	with: ['roles', 'provider'],
	search: {
		searchText: encodeURIComponent(value) || null,
	},
	searchFields: {
		searchText: 'ilike',
	},
});

export const getProvidersParams = (value) => ({
	search: {
		name: encodeURIComponent(value) || null,
	},
	searchFields: {
		name: 'ilike',
	},
});

export const getProvider = () => ({
	append: 'users_by_roles',
});
