import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Info } from 'components/Icons';

import ps from '../style.module.scss';
import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
const BatteryStatus = (props) => {
	const { data, t } = props;

	return (
		<div className={ps.oneThird}>
			<div className={cs(ps.panel, s.panel)}>
				<h2 className={ps.title}>
					{t('battery_health_statistics')}
					<Info
						fill='#a5bad4'
						data-tip=''
						data-for='BatteryStatus'
						className={ps.infoIconTrigger}
					/>
				</h2>
				<ReactTooltip
					id='BatteryStatus'
					className={ps.tooltip}
					place='bottom'
					type='light'
					effect='solid'
				>
					<Info fill='#a5bad4' className={ps.infoIconTooltip} />
					<p>{t('tooltip_total_battery_health_errors_of_30_days')}</p>
				</ReactTooltip>
				<div className={s.inner}>
					<span className={s.period}>
						{data.group.id === 11
							? t('label_G5_total_battery_errors_last_30_days')
							: data.group.id === 12
							  ? t('label_P4_Via_total_battery_errors_last_30_days')
							  : data.group.id === 13
							    ? t('label_P6_Rove_total_battery_errors_last_30_days')
							    : t('label_G4_total_battery_errors_last_30_days')}
					</span>
					<h3 className={s.recently}>{data.group.last_month}</h3>
				</div>
				<span className={s.total}>
					{data.group.id === 11
						? t('label_total_G5_battery_errors')
						: data.group.id === 12
						  ? t('label_total_P4_Via_battery_errors')
						  : data.group.id === 13
						    ? t('label_total_P6_Rove_battery_errors')
						    : t('label_total_G4_battery_errors')}
					: {data.group.all}
				</span>
				<div className={s.inner}>
					<span className={s.period}>
						{t('label_G4_G5_P4Via_P6Rove_total_battery_errors_last_30_days')}
					</span>
					<h3 className={s.recently}>{data?.total?.last_month}</h3>
				</div>
				<span className={s.total}>
					{t('label_total_battery_errors')}: {data?.total?.all}
				</span>
			</div>
		</div>
	);
};

BatteryStatus.defaultProps = {
	data: {
		group: {
			id: null,
			last_month: null,
			all: null,
		},
		total: {
			last_month: null,
			all: null,
		},
	},
};

BatteryStatus.propTypes = {
	data: PropTypes.shape({
		group: PropTypes.shape({
			id: PropTypes.number,
			last_month: PropTypes.number,
			all: PropTypes.number,
		}),
		total: PropTypes.shape({
			last_month: PropTypes.number,
			all: PropTypes.number,
		}),
	}),
};

export default memo(withTranslation()(BatteryStatus));
