import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Input, Select } from 'components';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import es from 'date-fns/locale/es';
import s from '../style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { isToday } from 'date-fns';

registerLocale('us', enUS);
registerLocale('fr', fr);
registerLocale('es', es);
const FirmwareInfo = ({
	models,
	selectedModel,
	releasedDate,
	partNum,
	version,
	onChange,
	onSelectDate,
	// statusId,
	t,
}) => {
	const [startDate, setStartDate] = useState(
		releasedDate ? new Date(releasedDate) : new Date(),
	);
	const dateFormat = isToday(startDate) ? `'Today', d MMM yyyy` : 'd MMM yyyy';

	const modelsConfig = {
		displayKey: 'description', // show description in model name
		label: {
			path: 'description', // show description in model name
		},
		placeholder: t('select_model'),
	};
	const onDateChange = (date) => {
		onSelectDate(date);
		setStartDate(date);
	};

	return (
		<Fragment>
			<div className={s.row}>
				<div className={s.col}>
					<div className={s.editLabel}>
						{t('product')} <span className={s.mandatory}> {'*'}</span>
					</div>
					<Select
						checked={selectedModel && { [selectedModel.id]: selectedModel }}
						className={s.input}
						data={models}
						config={modelsConfig}
						onItemClick={(data) => onChange(null, 'concentrator_model', data)}
						newDesign={true}
					/>
				</div>
				<div className={s.col}>
					<div className={s.editLabel}>
						{t('version')} <span className={s.mandatory}> {'*'}</span>
					</div>
					<Input
						value={version || ''}
						className={s.input}
						// elementType="mask"
						elementConfig={{
							type: 'text',
							placeholder: t('version') /*, guide: false*/,
						}}
						mask={[
							/[0-9]/,
							/[0-9,.]/,
							/[0-9,.]/,
							/[0-9,.]/,
							/[0-9,.]/,
							/[0-9,.]/,
							/[0-9]/,
						]}
						changed={(e) => onChange(e, 'version')}
						newDesign={true}
					/>
				</div>
			</div>

			<div className={s.row}>
				<div className={s.col}>
					<div className={s.editLabel}>
						{t('release_date')} <span className={s.mandatory}> {'*'}</span>
					</div>
					<DatePicker
						className={s.dateInput}
						selected={startDate}
						minDate={new Date()}
						calendarClassName={s.datepicker}
						onChange={onDateChange}
						disabledKeyboardNavigation
						locale={i18next.language}
						dateFormat={dateFormat}
					/>
				</div>
				<div className={s.col}>
					<div className={s.editLabel}>
						{t('part_number')}{' '}
						{/* {statusId === 2 || statusId === 4 ? (
							<span className={s.mandatory}> {'*'}</span>
						) : null} */}
					</div>
					<Input
						value={partNum || ''}
						className={s.input}
						// elementType="mask"
						elementConfig={{
							type: 'text',
							placeholder: t('part_number') /*, guide: false*/,
						}}
						mask={[
							/[0-9]/,
							/[0-9,-]/,
							/[0-9,-]/,
							/[0-9,-]/,
							/[0-9,-]/,
							/[0-9,-]/,
							/[0-9,-]/,
							/[0-9]/,
						]}
						changed={(e) => onChange(e, 'part_num')}
						newDesign={true}
					/>
				</div>
			</div>
		</Fragment>
	);
};

FirmwareInfo.defaultProps = {
	onChange: () => {},
};

FirmwareInfo.propTypes = {
	onChange: PropTypes.func,
};

export default withTranslation()(FirmwareInfo);
