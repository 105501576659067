import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { routers } from 'settings';
import { permissions } from 'helpers';

import {
	Dashboard,
	Firmware,
	Notifications,
	Profile,
	Users,
	PatientDashboard,
} from 'components/Icons';

import { menuLogo } from 'img';
import { copyRight, uniqueClass } from 'config';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import LanguagePopover from '../LanguagePopover';

class Layout extends Component {
	state = {
		isSidebarOpen: false,
	};
	toggleSidebar = () => {
		this.setState((prevState) => ({ isSidebarOpen: !prevState.isSidebarOpen }));
	};
	render() {
		const {
			user: userName = 'User Name',
			termsModal,
			t,
			isConsentGiven,
		} = this.props;
		const { isSidebarOpen } = this.state;

		return (
			<div className={s.layout}>
				<div className={`${s.leftSideBar} ${isSidebarOpen ? s.open : ''}`}>
					<div className={s.toggleLogoContainer}>
						<div className={`${s.toggleButton} ${isSidebarOpen ? s.open : ''}`}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='#999'
								onClick={this.toggleSidebar}
								height='24'
								viewBox='0 -960 960 960'
								width='24'
							>
								<path d='M160-240q-17 0-28.5-11.5T120-280q0-17 11.5-28.5T160-320h640q17 0 28.5 11.5T840-280q0 17-11.5 28.5T800-240H160Zm0-200q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520h640q17 0 28.5 11.5T840-480q0 17-11.5 28.5T800-440H160Zm0-200q-17 0-28.5-11.5T120-680q0-17 11.5-28.5T160-720h640q17 0 28.5 11.5T840-680q0 17-11.5 28.5T800-640H160Z' />
							</svg>
						</div>
						<div
							className={[
								s.logo,
								s[uniqueClass],
								isSidebarOpen ? s.open : '',
							].join(' ')}
						>
							<img src={menuLogo} alt='Inogen Connect Portal' />
						</div>
					</div>

					<nav className={s.navigation}>
						<ul className={s.navList}>
							{permissions.check('dashboard') && isConsentGiven && (
								<li>
									<NavLink exact to={routers.index} activeClassName={s.active}>
										<Dashboard /> <span>{t('dashboard')}</span>
									</NavLink>
								</li>
							)}
							{permissions.check('dashboard_patient') && isConsentGiven && (
								<li>
									<NavLink
										exact
										to={
											permissions.check('dashboard')
												? routers.patientDashboardIndex
												: routers.index
										}
										activeClassName={s.active}
									>
										<PatientDashboard /> <span>{t('patientDashboard')}</span>
									</NavLink>
								</li>
							)}
							{permissions.check('notifications') && isConsentGiven && (
								<li>
									<NavLink
										to={routers.notifications}
										activeClassName={s.active}
									>
										<Notifications /> <span>{t('notifications')}</span>
									</NavLink>
								</li>
							)}
							{(permissions.check('providers') || permissions.check('users')) &&
								isConsentGiven && (
									<li>
										<NavLink to={routers.users} activeClassName={s.active}>
											<Users /> <span>{t('providers_&_users')}</span>
										</NavLink>
									</li>
								)}
							{permissions.check('users_provider_admin') && isConsentGiven && (
								<li>
									<NavLink to={routers.users} activeClassName={s.active}>
										<Users /> <span>{t('users')}</span>
									</NavLink>
								</li>
							)}
							{permissions.check('firmwares') && isConsentGiven && (
								<li>
									<NavLink to={routers.firmware} activeClassName={s.active}>
										<Firmware /> <span>{t('firmwares')}</span>
									</NavLink>
								</li>
							)}
							<li className={s.offset}>
								<NavLink
									title={userName || t('my_profile')}
									to={routers.profile}
									activeClassName={s.active}
								>
									<Profile /> <span>{userName || t('my_profile')}</span>
								</NavLink>
							</li>
							<li className={s.p10}>
								<LanguagePopover />
							</li>
						</ul>
					</nav>

					<div className={s.copy}>
						<button className={s.termsBtn} onClick={termsModal.open}>
							{t('terms_of_use')}
						</button>
						<div>&copy; {copyRight}</div>
					</div>
				</div>

				<div className={s.main}>{this.props.children}</div>
			</div>
		);
	}
}

export default withTranslation()(Layout);
