import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components';

import s from '../style.module.scss';
import { constants } from '../../../../../helpers';
import { withTranslation } from 'react-i18next';

const ChangeSummary = ({ data, onChange, t }) => {
	data = data.filter((item) => item);
	let description = data.join('');
	const address1Validation = () => {
		let length;
		length = data.length === 0 ? 0 : data[0].length;
		const maxLength = constants.CHANGE_SUMMARY_INPUT;
		return (
			<span className={s.alignCountValidation}>
				{length}/
				<span
					className={
						length >= maxLength ? s.red : '' + length > 0 ? s.green : ''
					}
				>
					{maxLength - length}
				</span>
			</span>
		);
	};
	const config = {
		type: 'text',
		placeholder: description ? '' : t('change_summary'),
	};
	return (
		<Fragment>
			<div className={s.btnContainer}>
				<div className={s.editLabel}>
					{t('change_summary')}
					<span className={s.mandatory}> {'*'}</span>
				</div>
				<div className={s.btnContainer}>
					<Input
						className={[s.input, s.inputAlignment].join(' ')}
						key={'change_summary'}
						elementType='input'
						elementConfig={config}
						value={description || ''}
						changed={(event) => onChange(event)}
						newDesign={true}
						maxlength={constants.CHANGE_SUMMARY_INPUT}
					/>
					{address1Validation()}
				</div>
			</div>
		</Fragment>
	);
};

ChangeSummary.defaultProps = {
	data: [],
};

ChangeSummary.propTypes = {
	data: PropTypes.array,
};

export default withTranslation()(ChangeSummary);
